import React, { useState, useEffect } from "react";
import axios from "axios";
import img1 from "../Images/person_3_sm.jpg";
import "./HomeSlid.css";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { LiaComments } from "react-icons/lia";
import { FaEye } from "react-icons/fa";
import AnswersGiven from "./AnswersGiven";
import GiveAnswer from "./GiveAnswer";
import Loader from "./Loader";
const MostCommented = ({ companyId }) => {
  return (
    <div>
      
    </div>
  )
}

export default MostCommented
