import React from 'react';
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';


const Analytics = () => {
  return (
    <>
  
  <Navbar /> 
  <div className=''>
            <main className="main-content">
            <iframe className='kkkk' src="https://lookerstudio.google.com/embed/reporting/fcb19fae-4fe8-49e1-9cc0-d3edfeb1e57a/page/jQa8D" frameborder="0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>

              </main>
          </div>
          <Footer />
     </>
  );
};

export default Analytics;






